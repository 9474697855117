<template>
  <div class="appoint-product">
    <color-text-btn style="font-size: 14px" :loading="loading" @click="to">指定产品</color-text-btn>
    <distributionDialog
      type="default"
      v-model="value"
      v-if="showDistributionDialog"
      :visible.sync="dialogVisible"
      :resetMergeData="resetMergeData"
      v-on="$listeners"
      :isAppoint="isAppoint"
      @successHandler="successHandler"
    >
      <template #isShowAppoint>
        是否指定原型：
        <el-switch
          v-model="isAppoint"
          :active-value="1"
          :inactive-value="0"
          active-color="#3841DB"
          inactive-color="#909399"
        >
        </el-switch>
      </template>
    </distributionDialog>
  </div>
</template>

<script>
import ColorTextBtn from '@/components/base/colorTextBtn.vue'
import distributionDialog from './distributionDialog/index.vue'
import { getAppointList } from '@/api/fadexApi'
import { commonEheaderMixin } from '@/mixins'
export default {
  mixins: [commonEheaderMixin],
  components: {
    ColorTextBtn,
    distributionDialog
  },
  props: {
    channelRow: {
      type: Object
    }
  },
  provide() {
    return {
      channelRow: this.channelRow
    }
  },
  data() {
    return {
      loading: false,
      value: [],
      resetMergeData: {},
      showDistributionDialog: false,
      isAppoint: 0
    }
  },
  computed: {},
  watch: {
    dialogVisible(newVal) {
      if (newVal) return
      this.$nextTick(() => {
        this.value = []
        this.showDistributionDialog = false
      })
    }
  },
  methods: {
    async to() {
      this.showDistributionDialog = true
      console.log(this.channelRow, 'this.channelRow')
      this.isAppoint = this.channelRow.isAssignPrototype || 0
      try {
        const { detail } = await getAppointList({ relateId: this.channelRow.relationId })
        this.value = (detail || []).map(({ prototypeId }) => prototypeId)
        console.log('this.value', this.value)
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      } catch {}
    },
    successHandler() {
      this.$emit('success')
    }
  }
}
</script>

<style scoped lang="scss">
.appoint-product {
  display: inline-block;
}
</style>
