var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"app-container"},[_c('div',{directives:[{name:"scrollTop",rawName:"v-scrollTop"}]},[_c('avue-crud',{ref:"crud",staticClass:"search-form non-expand-icon",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading,"span-method":_vm.spanMethod,"row-class-name":_vm.rowClassName},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"expressCompanyId",fn:function(ref){
var row = ref.row;
return [(row.tableLevel === '1')?[(!row.tableExpanded || _vm.loading[row.customId])?_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:detail']),expression:"['externaladmin:fedex:had:detail']"}],staticClass:"view-btn",attrs:{"type":"text","loading":_vm.loading[row.customId],"size":"small"},on:{"click":function($event){return _vm.viewChildren(row)}}},[(_vm.loading[row.customId])?[_vm._v(" 加载中... ")]:_vm._e(),(!_vm.loading[row.customId])?[_vm._v(" 查看"),_c('i',{staticClass:"el-icon-plus"})]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.companyName))]),_c('Authorize',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:addAuth']),expression:"['externaladmin:fedex:had:addAuth']"}],attrs:{"sup_this":_vm.sup_this,"data":row,"initCallBack":_vm.cacheRefreshAccountList(row)}})]:(row.tableLevel === '2')?[_c('el-button',{staticClass:"view-btn",class:{ 'is-expanded': row.tableExpanded },attrs:{"type":"text","size":"small","loading":_vm.loading[row.customId]},on:{"click":function($event){return _vm.viewChildren(row)}}},[(_vm.loading[row.customId])?[_vm._v(" 加载中... ")]:[_vm._v(" "+_vm._s(row.tableExpanded ? '收起' : '展开')+" "),_c('i',{staticClass:"el-icon-d-arrow-right"})]],2),_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.accountName))])]:(row.tableLevel === '3')?[_c('avue-crud',{attrs:{"data":_vm.channelDataFilter(_vm.channelAccountEnabledData[row.accountId], row.accountId),"option":_vm.channelOption,"tableLoading":_vm.loading[("channelTable" + (row.accountId) + " " + (row.companyId))]},scopedSlots:_vm._u([{key:"cnNameHeader",fn:function(scope){return [_c('el-input',{attrs:{"size":"mini","placeholder":"搜索物流方式","suffix-icon":"el-icon-search"},model:{value:(_vm.search[row.accountId]),callback:function ($$v) {_vm.$set(_vm.search, row.accountId, $$v)},expression:"search[row.accountId]"}})]}},{key:"isEnabled",fn:function(ref){
var isEnabled = ref.row.isEnabled;
return [_c('status-button',{attrs:{"valid":isEnabled === _vm.SYS_YES,"validText":"已启用","invalidText":"未启用"}})]}},{key:"isCustomsDeclare",fn:function(ref){
var isCustomsDeclare = ref.row.isCustomsDeclare;
return [_c('status-button',{attrs:{"valid":isCustomsDeclare === _vm.SYS_YES,"validText":"需要","invalidText":"不需要"}})]}},{key:"menu",fn:function(ref){
var channelRow = ref.row;
return [(channelRow.isEnabled === _vm.SYS_YES && _vm.appointPrototype)?_c('appointProductBtn',{attrs:{"channelRow":channelRow},on:{"success":function($event){return _vm.refreshChannelList(row)}}}):_vm._e(),(channelRow.isEnabled === _vm.SYS_YES)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_c('color-text-btn',{attrs:{"size":"medium","type":channelRow.isEnabled === _vm.SYS_YES ? 'danger' : '',"loading":_vm.loading[("channel" + (row.accountId) + (channelRow.id) + "AuthBtn")],"disabled":!row.isAuthorized},on:{"click":function($event){return _vm.handleAccountChannelAuth(
                    {
                      id: channelRow.id,
                      relationId: channelRow.relationId,
                      companyId: channelRow.companyId,
                      accountId: row.accountId,
                      isEdt: 0
                    },
                    channelRow.isEnabled === _vm.SYS_YES,
                    channelRow.cnName
                  )}}},[_vm._v(" "+_vm._s(channelRow.isEnabled === _vm.SYS_YES ? '停用' : '启用')+" ")]),(channelRow.isEnabled === _vm.SYS_YES)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),(channelRow.isEnabled === _vm.SYS_YES)?_c('ColorTextBtn',{staticStyle:{"font-size":"14px"},on:{"click":function($event){return _vm.handleAccountChannelAuth(
                    {
                      id: channelRow.id,
                      relationId: channelRow.relationId,
                      companyId: channelRow.companyId,
                      accountId: row.accountId,
                      isEdt: 1
                    },
                    channelRow.isEnabled === _vm.SYS_NO,
                    channelRow.cnName
                  )}}},[_vm._v("设置")]):_vm._e()]}}],null,true)})]:[_vm._v(" 暂无数据 ")]]}},{key:"isAuthorized",fn:function(ref){
                  var isAuthorized = ref.row.isAuthorized;
return [_c('status-button',{attrs:{"valid":isAuthorized === _vm.SYS_YES,"validText":"已授权","invalidText":"未授权"}})]}},{key:"menu",fn:function(ref){
                  var row = ref.row;
return [(row.tableLevel === '1')?[_c('setCustomDeclaration',{attrs:{"sup_this":_vm.sup_this,"dialogData":row}})]:(row.isAuthorized === _vm.SYS_YES)?[(row.id)?_c('UpdateAuthorize',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:doEdit']),expression:"['externaladmin:fedex:had:doEdit']"}],attrs:{"sup_this":_vm.sup_this,"data":row,"afterSubmit":_vm.accountRefreshAuth(row, false)}}):_vm._e(),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('color-text-btn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:doCancelAuthorize']),expression:"['externaladmin:fedex:had:doCancelAuthorize']"}],attrs:{"size":"medium","type":"danger","loading":_vm.loading[row.customId + 'AuthBtn']},on:{"click":function($event){return _vm.handleAccountAuth(row, row.isAuthorized === _vm.SYS_YES)}}},[_vm._v("取消授权")])]:[_c('Popover',{on:{"sureHandler":function($event){return _vm.delAccount([row.id], row.$parentRow, $event)}},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('p',[_vm._v("您确定要删除该账号?")])]},proxy:true},{key:"reference",fn:function(ref){
                  var loading = ref.scope;
return [_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:doDelete']),expression:"['externaladmin:fedex:had:doDelete']"}],attrs:{"type":"text","danger":"","loading":loading}},[_vm._v("删除")])]}}],null,true)}),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('UpdateAuthorize',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:fedex:had:doReauthorize']),expression:"['externaladmin:fedex:had:doReauthorize']"}],attrs:{"sup_this":_vm.sup_this,"data":row,"afterSubmit":_vm.accountRefreshAuth(row, row.isAuthorized === _vm.SYS_YES)}},[_vm._v("重新授权")])]]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }